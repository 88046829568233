jQuery(document).ready(function ($) {
    var button = document.querySelector('#button');

    button.addEventListener("click", function () {

        if (button.classList.contains('collapsed') === true) {
            button.classList.remove("collapsed");
            $('nav').show();
        }
        else {
            button.classList.add("collapsed");
            $('nav').hide();
            $(window).resize(function () {
                if ($(document).width() > 776) {
                    $('nav').show();
                }
                if ($(document).width() < 776) {
                    $('nav').hide();
                }
            });
        }
    });

    var $w = $(window),
        $canvases = $('body');
    var nav = document.querySelector("nav");
    var section = document.querySelector(".mainSection");
    var nav2 = document.querySelector("#button");
    var logo = document.querySelector('.logo');

    $w.scroll(function () {
        var d = $w.height() / 2 + $w.scrollTop();
        $canvases.each(function () {
            var $this = $(this),
                ptop = $this.offset().top,
                total = ptop + $this.height();

            if (d < 650) {
                nav.classList.remove("inMoveNav", "animated", "fadeIn");
                nav2.classList.remove("inMoveMenu");
                logo.classList.remove("more");
                section.classList.remove("fix");
            }
            else {
                nav.classList.add("inMoveNav", "animated", "fadeIn");
                nav2.classList.add("inMoveMenu");
                logo.classList.add("more");
                section.classList.add("fix");
            }
            $this.toggleClass('active', ptop < d && total >= d);
        });
    }).scroll(); //trigger the handler when DOM is ready
});
